import * as React from 'react'
import {graphql} from 'gatsby'
import Layout from '../components/layout'
import Modal from '../components/modal'
import SEO from '../components/seo'
import FreeProductOffer from '../components/FreeProductOffer'
import {Link} from 'gatsby'

const normalizeString = str =>
  str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()

export default function VocabularioTemplate({data}) {
  const allWords = data.allMotsJson.nodes
  const [searchTerm, setSearchTerm] = React.useState('')

  const filteredWords = allWords.filter(
    word =>
      normalizeString(word.mot).includes(normalizeString(searchTerm)) ||
      normalizeString(word.trad).includes(normalizeString(searchTerm)),
  )

  const capitalizeFirstLetter = str =>
    str.charAt(0).toUpperCase() + str.slice(1)

  return (
    <Layout>
      <SEO
        title="Aprende Francés: Vocabulario"
        description="Descubre palabras útiles en francés y su traducción al español. Mejora tu vocabulario con Lemot."
        hasJsonLd
      />
      <div className="wrapper">
        <Modal />
        <section className="section is-medium">
          <h1 className="title has-text-centered">
            Busca una palabra en francés
          </h1>
          <input
            className="input is-large is-rounded"
            type="text"
            placeholder="Escribe aquí..."
            value={searchTerm}
            onChange={e => setSearchTerm(e.target.value)}
          />
        </section>
        <section className="section is-small">
          <div className="columns is-multiline">
            {filteredWords.map(word => (
              <div className="column is-one-quarter" key={word.mot}>
                <Link to={`/${word.trad.toLowerCase()}/#word`} className="">
                  <div
                    className="card has-background-light"
                    style={{
                      borderRadius: '8px',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      transition: 'transform 0.2s, box-shadow 0.2s',
                      background: 'linear-gradient(to top, #ffffff, #f0f0f0)',
                    }}
                    onMouseOver={e => {
                      e.currentTarget.style.transform = 'scale(1.05)'
                      e.currentTarget.style.boxShadow =
                        '0 6px 12px rgba(0, 0, 0, 0.2)'
                    }}
                    onMouseOut={e => {
                      e.currentTarget.style.transform = 'scale(1)'
                      e.currentTarget.style.boxShadow =
                        '0 4px 8px rgba(0, 0, 0, 0.1)'
                    }}
                  >
                    <div
                      className="card-content"
                      style={{height: '200px', overflow: 'hidden'}}
                    >
                      <div className="has-text-weight-bold top-bottom-overflow-fade ">
                        <span>{capitalizeFirstLetter(word.mot)}</span>
                        <span>{` (${capitalizeFirstLetter(word.trad)})`}</span>
                      </div>
                      <div
                        className="content transparent_gradient"
                        style={{
                          marginTop: '0.5rem',
                          color: '#333',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '0.5rem',
                        }}
                      >
                        <span>{capitalizeFirstLetter(word.ejemplo)}</span>
                        <br />
                        <span>{` (${capitalizeFirstLetter(
                          word.tradEjemplo,
                        )})`}</span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </section>

        <div className="wrapper">
          <section className="section is-small">
            <FreeProductOffer
              image={data.FreeProductImage.childImageSharp.fluid}
              dataTarget="FreeProductModalBlog"
              modalId="FreeProductModalBlog"
              formSrc="https://e7b4fa4e.sibforms.com/serve/MUIFADoHrK0MDgEAVdTXl_Dlls_5vmNKLPCWrO2nkkU49b64vmfk_7nP39LELy5qufBIhGrBSmnsZJLEn--wU8e3WaeNlvoDzI6Mp-5fWr-vFBM7cb96DRxHw9wdwF19kXoickyFZRdzA9AnatNrUC6CZ9w9VlKffbnAmVdDz3ezJeevyOfY4K3ZoEaLMQVrhUbbbbDU4hYmHQE5"
            />
          </section>
        </div>
        <div className="notification is-white has-text-black has-text-centered">
          <strong>
            ¿Cómo podemos mejorar esta página para ti? Háznoslo saber usando el
            chat.
          </strong>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    images: allFile(
      filter: {relativeDirectory: {eq: "index"}}
      sort: {fields: name}
    ) {
      edges {
        node {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    allMotsJson {
      nodes {
        mot
        trad
        ejemplo
        tradEjemplo
      }
    }
    FreeProductImage: file(name: {eq: "FreeProductImageVerb"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
